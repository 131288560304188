'use client';
import React, { FC, PropsWithChildren } from 'react';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { GraphqlProvider } from '@/api/providers/GraphqlProvider/GraphqlProvider';
import { ErrorBoundary } from '@/common/errors/ErrorBoundary';
import { AuthenticationProvider } from '@/common/providers/AuthenticationProvider/AuthenticationProvider';
import { AuthorizationProvider } from '@/common/providers/AuthorizationProvider/AuthorizationProvider';
import { LanguageSelector } from '@/common/views/LanguageSelector';

const OrganizationLayout: FC<PropsWithChildren> = ({ children }) => (
  <UserProvider>
    <AuthenticationProvider>
      <GraphqlProvider>
        <AuthorizationProvider>
          <ErrorBoundary>
            <LanguageSelector />
            {children}
          </ErrorBoundary>
        </AuthorizationProvider>
      </GraphqlProvider>
    </AuthenticationProvider>
  </UserProvider>
);

export default OrganizationLayout;
