import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

let checkingSession = false;

export const useCheckSession = () => {
  const apolloClient = useApolloClient();

  const checkSession = useCallback(async () => {
    try {
      if (!checkingSession) {
        checkingSession = true;

        // Reset the cache and run all active queries again, because the permissions and token changed
        await apolloClient.cache.reset();
      }
    } finally {
      checkingSession = false;
    }
  }, [apolloClient.cache]);

  return checkSession;
};
