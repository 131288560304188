import { DecodedJWT } from '@/types/decodedJwt';

export const getSession = async (params?: { refresh?: boolean }) => {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params || {})) {
    searchParams.append(key, value.toString());
  }
  const response = await fetch('/api/auth/session?' + searchParams.toString());
  const data = await response.json();
  if (!response.ok) {
    throw new Error('Unauthorized');
  }
  return data as {
    token: string;
    claims: DecodedJWT;
  };
};
