'use client';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useRouter, useSearchParams } from 'next/navigation';
import {
  AuthenticationContext,
  AuthenticationContextType,
  initialValue,
} from './AuthenticationContext';
import { Spinner } from '@/common/Spinner';
import { getSession } from '@/common/utils/getSession';
import { publicApiJSON } from '@/common/utils/publicApi/publicApi';

const getOrganizationIdByName: (
  organizationName: string
) => Promise<{ organizationId: string }> = async (organizationName) =>
  publicApiJSON({
    path: 'getOrganizationId',
    method: 'POST',
    body: {
      organizationName,
    },
  });

type AuthenticationProviderProps = {
  auth0OrgIdOrName?: string;
} & PropsWithChildren;

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({
  children,
  auth0OrgIdOrName,
}) => {
  const router = useRouter();

  const searchParams = useSearchParams();

  const fromQuery = useMemo(
    () => searchParams.get('organization') || undefined,
    [searchParams]
  );

  const { user } = useUser();

  const [loggingEnabled, setLoggingEnabled] = useState<boolean>(false);

  const [claims, setClaims] = useState<
    AuthenticationContextType['claims'] | undefined
  >(initialValue.claims);

  const [jwtToken, setJwtToken] = useState<
    AuthenticationContextType['jwtToken'] | undefined
  >(initialValue.jwtToken);

  const isRegisteredViaSocialProvider = useMemo(
    () => (user?.sub ? !user.sub.startsWith('auth0|') : false),
    [user]
  );

  // Set the auth0 organization id
  const [auth0OrgId, setAuth0OrgId] = useState<
    AuthenticationContextType['auth0OrgId'] | undefined
  >(initialValue.auth0OrgId);

  useEffect(() => {
    // Signed in via /login-url
    if (auth0OrgIdOrName && !auth0OrgIdOrName.startsWith('org_')) {
      // Get organization id via name, e.g. if the user tried to sign in via /login/rellify
      getOrganizationIdByName(auth0OrgIdOrName)
        .then(({ organizationId }) => {
          setAuth0OrgId(organizationId);
        })
        .catch((error) => {
          console.error(
            '[AuthenticationProvider] Error in getOrganizationIdByName',
            error
          );
          setAuth0OrgId(undefined);
        });
    } else {
      setAuth0OrgId(() => {
        // Signed in via /login/org_myOrdId
        if (auth0OrgIdOrName) {
          return auth0OrgIdOrName;
        }

        // Signed in via /organizations/org_myOrdId/...
        const fromPath = location.pathname
          ?.split('/organizations/')?.[1]
          ?.split('/')?.[0];
        if (fromPath) {
          return fromPath;
        }

        // Signed in via ...?organization=org_myOrdId
        if (fromQuery) {
          return fromQuery;
        }

        return undefined;
      });
    }
  }, [auth0OrgIdOrName, fromQuery]);

  const loadedChildren = !!user && !!auth0OrgId && !!claims && !!jwtToken;

  // Logging
  useEffect(() => {
    // Logging is enabled for the user
    if (
      claims &&
      claims.logging &&
      (process.env.NEXT_PUBLIC_NODE_ENV !== 'development' ||
        process.env.NEXT_PUBLIC_DEBUG_LOGGING)
    ) {
      // Init logging
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET, {
        release: process.env.NEXT_PUBLIC_BUILD_NUMBER,
        dom: {
          privateAttributeBlocklist: ['data-sanitized'],
        },
        network: {
          requestSanitizer: (request) => ({
            ...request,
            headers: Object.fromEntries(
              Object.entries(request.headers).filter(
                ([key]) => key.toLowerCase() !== 'authorization'
              )
            ),
            body: request.body?.includes('applicationPassword')
              ? 'SANITIZED'
              : request.body,
          }),
        },
      });
      setupLogRocketReact(LogRocket);

      // Identify the user based on the given claims (same across all sessions)
      LogRocket.identify(claims.userId);
      // LogRocket.identify(claims.userId, {
      //   nickname: user?.nickname ?? '',
      //   email: user?.email ?? '',
      //   organizationId: claims.organizationId,
      //   role: claims.auth0Role,
      // });

      // Track organization related information not user- but session-based, because a user could log into multiple organizations
      LogRocket.track('organization', {
        organizationId: claims.organizationId,
        role: claims.auth0Role,
      });

      // Enable logging
      setLoggingEnabled(true);
    } else {
      // Disable logging
      setLoggingEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(claims)]); // Only trigger, if the claims are different

  const signIn = useCallback(() => router.push('/api/auth/login'), [router]);

  useEffect(() => {
    if (user?.id) {
      return;
    }

    getSession()
      .then(({ claims: sessionClaims, token }) => {
        const auth0OrgIdFromToken = sessionClaims.org_id;
        // The organization ids differ, so we have to sign in the user again
        if (auth0OrgId && auth0OrgIdFromToken !== auth0OrgId) {
          signIn();
          return;
        }

        setClaims({
          organizationId: sessionClaims['https://rellify:orgId'],
          userId: sessionClaims['https://rellify:userId'],
          auth0Role: sessionClaims['https://rellify:auth0role'],
          logging: sessionClaims['https://rellify:logging'] !== false,
        });
        setJwtToken(token);
        setAuth0OrgId(auth0OrgIdFromToken);
      })
      .catch(() => {
        signIn();
      });
  }, [auth0OrgId, signIn, user?.id]);

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        isRegisteredViaSocialProvider,
        claims,
        jwtToken,
        auth0OrgId,
        loggingEnabled,
      }}
    >
      {loadedChildren ? children : <Spinner />}
    </AuthenticationContext.Provider>
  );
};
